import React from "react";
import { Box, Typography, Link } from "@mui/material";
import { PageProps } from "gatsby";

import Layout from "../../../components/Layout";
import { MetaData } from "../../../components/common/meta";
import Video, { VideoProps } from "./Video";
import DeepDiveArticle from "./DeepDiveArticle";

import { OutboundLink } from "gatsby-plugin-google-gtag";
import { StaticImage } from "gatsby-plugin-image";

import sideBrand2 from "../../../images/pitLine2.svg";
import lines from "../../../images/brandLines.svg";
import ArrowSquare from "../../../images/firedancer/arrow.inline.svg";


import cityBackground from "../../../images/career/bg.png";

const projectBox = {
  textAlign: "center",
  background: "#091A3D",
  border: "1px solid #4B608D",
  position: "relative",
  transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  minHeight: "105px",
  display: "flex",
  flexDirection: "column",
  p: 1.5,
  alignItems: "center",
  justifyContent: "center",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    border: "1px solid #4B608D",
    left: "10px",
    top: "-10px",
    zIndex: -1,
    transition: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
  },
  "& span": {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#091A3D",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
    opacity: "0",
    transition: ".3s ease-in-out",
  },
  "&:hover span": {
    opacity: 1,
  },
};


const mediaMentions = ({ location }: PageProps) => {

  const videos: VideoProps[] = [
    {
      title: "Validated:  Firedancer w/ Kevin Bowers",
      desc: "In this episode, Austin visits Jump Crypto's office in Chicago (thePit) to talk about Firedancer, Solana's second independent validator client. They discuss its roadmap, its rollout, and the ways in which it will optimize and improve upon the architecture of Solana's existing client. Kevin elaborates on his unconventional views on programming languages, scaling solutions, data flow optimization, and much more.",
      descPreformatted: false,
      mediaUrl: "https://www.youtube.com/watch?v=QL_yJWI-kNI",
      type: "Video",
      cover: "/resources/validated-video-cover.png",
      date: "June 5, 2024",
    },
    {
      title: "Firedancer | Technical Walkthrough",
      desc: `Join us as Firedancer v.01 gives a technical walkthrough of their codebase.
- How is Firedancer unique?
- Where are they most worried about bugs?
- Find out this and more`,
      descPreformatted: true,
      mediaUrl: "https://www.youtube.com/watch?v=KJzZ5QApW2s",
      type: "Video",
      cover: "/resources/immunefi-technical-walkthrough-cover.png",
      date: "August 2, 2024",
    }
  ]

  return (
    <Layout>
      <MetaData title="Media | Firedancer" location={location} type="website" />

      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          display: { xs: "none", xl: "block" },
          zIndex: -1,
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={cityBackground}
          alt=""
          sx={{
            width: "100%",
            minWidth: "1200px",
            transform: "scale(1.05)",
            zIndex: -2,
            opacity: 0.8,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />

        <Box
          component="div"
          sx={{
            maxWidth: 800,
            mx: "auto",
            pt: "20vw",
            pb: { xs: 6, md: 14 },
            textAlign: "center",
          }}
        />

      </Box>

      <Box
        component="span"
        sx={{
          position: "relative",
          "&:before": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: 50,
            top: -130,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />


      <Box
        component="div"
        sx={{
          maxWidth: 1082,
          display: "flex",
          flexWrap: "nowrap",
          flexDirection: { xs: "column", sm: "row" },
          mx: "auto",
          mb: { xs: 4, md: 6 },

        }}
      >
        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "center", sm: "initial" },
            textAlign: { xs: "center", sm: "left" },
            mr: { xs: 0, sm: "auto" },
          }}
        >
          <Typography
            variant="h2"
            component="h3"
            sx={{ maxWidth: 310, mb: 2.4 }}
          >
            Media{" "}
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              Mentions_
            </Box>
          </Typography>
          <Typography sx={{ maxWidth: "200px" }}>
            Read about Firedancer in the news.
          </Typography>
        </Box>
        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "initial", sm: "end" },
            px: { xs: 1, sm: 1, md: 1.3 },
            flex: { xs: "0 1 100%", sm: "0 1 230px" },
          }}
        >
          <Link
            component={OutboundLink}
            href="https://www.alchemy.com/overviews/what-is-firedancer"
            target="_blank"
            rel="noreferrer"
            sx={projectBox}
          >
            <StaticImage
              src="../../images/firedancer/alchemy.png"
              placeholder="blurred"
              alt="Alchemy"
              loading="lazy"
              style={{ objectFit: "cover", marginBottom: 11 }}
            />
            <Typography sx={{ color: "#1CE7C2" }}>January 20, 2023</Typography>
            <Box component="span">
              <ArrowSquare />
              .Read more
            </Box>
          </Link>
        </Box>

        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "initial", sm: "end" },
            px: { xs: 1, sm: 1, md: 1.3 },
            flex: { xs: "0 1 50%", sm: "0 1 230px" },
          }}
        >
          <Link
            component={OutboundLink}
            href="https://fortune.com/crypto/2022/08/16/jump-crypto-to-build-solana-software-a-second-and-new-validator-client/"
            target="_blank"
            rel="noreferrer"
            sx={projectBox}
          >
            <StaticImage
              src="../../images/firedancer/fortune.png"
              placeholder="blurred"
              alt="Fortune"
              loading="lazy"
              style={{ objectFit: "cover", marginBottom: 11 }}
            />
            <Typography sx={{ color: "#1CE7C2" }}>August 16, 2022</Typography>
            <Box component="span">
              <ArrowSquare />
              .Read more
            </Box>
          </Link>
        </Box>


        <Box
          component="div"
          sx={{
            mb: 4,
            alignSelf: { xs: "initial", sm: "end" },
            px: { xs: 1, sm: 1, md: 1.3 },
            flex: { xs: "0 1 50%", sm: "0 1 230px" },
          }}
        >
          <Link
            component={OutboundLink}
            href="https://decrypt.co/107521/jump-crypto-plans-solana-validator-client-boost-performance-decentralization"
            target="_blank"
            rel="noreferrer"
            sx={projectBox}
          >
            <StaticImage
              src="../../images/firedancer/decrypt.png"
              placeholder="blurred"
              alt="Fortune"
              loading="lazy"
              style={{ objectFit: "cover", marginBottom: 11 }}
            />
            <Typography sx={{ color: "#1CE7C2" }}>August 16, 2022</Typography>
            <Box component="span">
              <ArrowSquare />
              .Read more
            </Box>
          </Link>
        </Box>
      </Box>


      <DeepDiveArticle />

      {videos.map((video, i) => (
        <Video {...video} key={video.title} />
      ))}

      <Box
        component="img"
        src={lines}
        sx={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />


    </Layout>
  );
};

export default mediaMentions;
